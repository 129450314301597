import AuthCard from "@/Components/AuthCard";
import { Button } from "@/Components/ui/Button";
import { Checkbox } from "@/Components/ui/Checkbox";
import { Facebook, Google, Twitter } from "@/Components/ui/Icons";
import { Input } from "@/Components/ui/Input";
import { PasswordInput } from "@/Components/ui/PasswordInput";
import { Separator } from "@/Components/ui/Separator";
import AuthLayout from "@/Layouts/AuthLayout";
import { Head, Link, useForm } from "@inertiajs/react";
import { t } from "i18next";
import { Key, Mail } from "lucide-react";
import { FormEventHandler, useEffect } from "react";
import { toast } from "sonner";

export default function Login({
    status,
    canResetPassword,
}: {
    status?: string;
    canResetPassword: boolean;
}) {
    const { data, setData, post, processing, errors, reset } = useForm({
        email: "",
        password: "",
        remember: false,
    });

    useEffect(() => {
        return () => {
            reset("password");
        };
    }, []);
    useEffect(() => {
        if (errors.email) {
            toast.error(errors.email);
        }
    }, [errors]);
    const submit: FormEventHandler = (e) => {
        e.preventDefault();
        post(route("login"));
    };
    useEffect(() => {
        if (status) {
            toast(status);
        }
    }, [status]);
    console.log(errors);
    return (
        <AuthLayout>
            <Head title="Log in" />
            <AuthCard
                title={t("auth.login")}
                description={t("auth.loginDesc")}
                className="gap-2"
            >
                <div className="flex flex-col gap-4 sm:flex-row">
                    {/* <Button variant={"outline"} className="flex-1">
                        <Facebook className="w-4 h-4 mx-2" /> Facebook
                    </Button> */}

                    <Button variant={"outline"} className="flex-1">
                        <a href="google" className="flex items-center">
                            <Google className="w-4 h-4 mx-2" /> Google
                        </a>
                    </Button>

                    {/* <Button variant={"outline"} className="flex-1">
                        <Twitter
                            className="w-4 h-4 mx-2 fill-black"
                            width={"1rem"}
                        />{" "}
                        X
                    </Button> */}
                </div>
                <Separator text={t("auth.orContinueWithEmail")} />
                <form onSubmit={submit} className="flex flex-col gap-4">
                    <Input
                        placeholder={t("app.email")}
                        icon={Mail}
                        id="email"
                        type="email"
                        name="email"
                        value={data.email}
                        className="block w-full mt-1"
                        autoComplete="username"
                        onChange={(e) => setData("email", e.target.value)}
                        aria-label={t("app.email")}
                        error={errors.email}
                    />
                    <PasswordInput
                        placeholder={t("app.password")}
                        icon={Key}
                        id="password"
                        name="password"
                        value={data.password}
                        className="block w-full mt-1"
                        autoComplete="current-password"
                        onChange={(e) => setData("password", e.target.value)}
                        aria-label={t("app.password")}
                        error={errors.password}
                    />
                    <div className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                            <Checkbox
                                id="remember"
                                checked={data.remember}
                                onCheckedChange={(state) =>
                                    setData(
                                        "remember",
                                        state === "indeterminate"
                                            ? false
                                            : state
                                    )
                                }
                            />
                            <label
                                htmlFor="remember"
                                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                            >
                                {t("auth.rememberMe")}
                            </label>
                        </div>
                        {canResetPassword && (
                            <Link
                                href="/forgot-password"
                                className="underline text-primary"
                            >
                                {t("auth.forgotPassword")}
                            </Link>
                        )}
                    </div>
                    <Button
                        type="submit"
                        className="w-full"
                        variant={"gooeyLeft"}
                        disabled={processing}
                        isLoading={processing}
                    >
                        {t("auth.login")}
                    </Button>
                </form>
                <div className="mt-2 text-sm text-center">
                    {t("auth.dontHaveAccount")}{" "}
                    <Link
                        href={route("register")}
                        className="underline text-primary"
                    >
                        {t("auth.register")}
                    </Link>
                </div>
            </AuthCard>
        </AuthLayout>
    );
}
